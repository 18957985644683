import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    name: "test",
    token: localStorage.getItem("token"), // 初始化token
    userInfo: JSON.parse(localStorage.getItem("userInfo")), // 初始化用户信息
    tags: [],
    wpsToken: localStorage.getItem("wpsToken"), // wpsToken
    headerInput: "", // 头部输入框输入的参数
    messageInfo: [], // 消息中心消息信息,
    userHomPage: {},
    updateMsgCount: "", // 更新消息统计
  },
  mutations: {
    // 更新消息统计数据
    UPDATE_MSG: (state, updateMsgCount) => {
      state.updateMsgCount = updateMsgCount
    },
    // 个人中心消息数据
    MESSAGE: (state, messageInfo) => {
      state.messageInfo = messageInfo
    },
    // 存储token的方法，SET_WPSTOKEN设置token等于外部传递进来的值
    SET_TOKEN: (state, token) => {
      state.token = token
      localStorage.setItem("token", token)
    },
    HEADER_INPUT: (state, headerInput) => {
      state.headerInput = headerInput
    },
    // WPS的token
    SET_WPSTOKEN: (state, wpsToken) => {
      state.wpsToken = wpsToken
      localStorage.setItem("wpsToken", wpsToken)
    },
    /*
     * @Description: 用户信息
     * @MethodAuthor:  liulian
     * @Date: 2022-03-04 11:49:47
     * @param: ''
     */
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
      localStorage.setItem("userInfo", JSON.stringify(userInfo))
    },
    /*
     * @Description: 移除用户信息
     * @MethodAuthor:  liulian
     * @Date: 2022-04-19 14:20:46
     * @param: ''
     */
    REMOVE_INFO: (state) => {
      //  移除用户信息将用户所有的信息都置为空
      state.token = ""
      state.userInfo = {}
      localStorage.removeItem("token")
      // localStorage.setItem("userInfo",JSON.stringify(""))
      localStorage.removeItem("userInfo")
      state.userHomPage = {}
    },
    //  获取标签id
    gettags(state, tag) {
      state.tags = tag
    },
    // 用户主页信息
    getHomePage(state, userHomPage) {
      state.userHomPage = userHomPage
    },
  },
  actions: {
    setSearchKey(context, value) {
      context.commit("HEADER_INPUT", value)
    },
    setMessageData(context, value) {
      context.commit("MESSAGE", value)
    },
    // 更新消息统计
    setMsgCount(context, value) {
      context.commit("UPDATE_MSG", value)
    },
  },
  getters: {
    //  获取用户信息
    getUser: (state) => {
      return state.userInfo
    },
    getToken: (state) => {
      return state.token
    },
  },
  modules: {},
})
