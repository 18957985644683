<!--
* @description 底部
* @fileName index.vue
* @author liulian
* @date 2022/06/09 14:00:12
-->
<template>
  <div class="footer_container">
    <div class="con">
      <div class="top flex-row-between">
        <div class="item" v-for="(item, index) in items" :key="index">
          <img class="cursor-pointer" :src="item.icon" @click="goUrl(item)" />
        </div>
      </div>
      <div class="ul">
        <div class="flex-row-between">
          <div class="li" v-for="(item, index) in names" :key="index">
            <span class="font-size-18 font-weight-500 cursor-pointer" @click="goDetail(item)"> {{ item.title }}</span>
            <span v-if="index !== names.length - 1" class="icon font-size-14"></span>
          </div>
        </div>
      </div>
      <div class="phone flex">
        <div class="left flex">
          <img src="@/assets/images/footer/youxiang@2x.png" alt="icon" />
          <span class="font-size-16 font-weight-400">{{ contact.mail }}</span>
        </div>
        <span class="line"></span>
        <div class="right flex">
          <img src="@/assets/images/footer/dianhua@2x.png" alt="icon" />
          <span class="font-size-16 font-weight-400">{{ contact.mobile }}</span>
        </div>
      </div>
      <p class="bototm font-size-14 font-weight-400 text-align-center">{{ copyright.copyright }}</p>
    </div>
  </div>
</template>

<script>
import { navigationInfoApi } from "@/api/api.js"
export default {
  name: "index",
  components: {},
  data() {
    return {
      items: [
        {
          icon: require("../../assets/images/footer/icon1@2x.png"),
          key: "redbook_url",
        },
        {
          icon: require("../../assets/images/footer/icon2@2x.png"),
          key: "twitter_url",
        },
        {
          icon: require("../../assets/images/footer/icon3@2x.png"),
          key: "weibo_url",
        },
        {
          icon: require("../../assets/images/footer/icon4@2x.png"),
          key: "instagram_url",
        },
      ],
      names: [],
      contact: {}, // 联系方式
      copyright: {},
    }
  },
  computed: {},
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    /*
     * @Description: 跳转单页面详情
     * @MethodAuthor:  liulian
     * @Date: 2022-07-22 09:01:27
     * @param: ''
     */
    goDetail(item) {
      this.$router.push(`/navigationDetail?id=${item.id}`)
    },
    /*
     * @Description: 跳转链接
     * @MethodAuthor:  liulian
     * @Date: 2022-07-21 18:35:30
     * @param: ''
     */
    goUrl(item) {
      window.open(item.path)
    },
    async getList() {
      let data = await this.listApi()
      if (data.link) {
        let keys = Object.keys(data.link)
        keys.map((k) => {
          this.items.map((item) => {
            if (item.key === k) {
              this.$set(item, "path", data.link[k])
            }
          })
        })
      }
      this.names = data.page
      this.contact = data.contact
      this.copyright = data.copyright
    },
    listApi() {
      return new Promise((resolve, reject) => {
        navigationInfoApi().then((res) => {
          if (res.code === 10000) {
            resolve(res.data)
          }
        })
      })
    },
  },
}
</script>

<style scoped lang="scss">
.footer_container {
  .con {
    background-image: url("../../assets/images/footer/footer@2x.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 25.4375rem;

    .top {
      padding-top: 9.125rem;
      width: 22.5rem;
      margin: auto;
      img {
        width: 2.25rem;
        height: 2.25rem;
      }
    }
    .ul {
      width: 50.625rem;
      margin: 2.625rem auto;
      .li {
        color: #ffffff;
        display: flex;
        align-items: center;
        .icon {
          margin-left: 3.5rem;
          width: 0.375rem;
          height: 0.375rem;
          background-color: #fff;
          display: block;
          border-radius: 100%;
        }
      }
    }
    .phone {
      margin: 2.625rem auto;
      width: 26.5rem;
      color: #ffffff;
      img {
        width: 1.375rem;
        height: 1.375rem;
        margin-right: 0.5rem;
      }
      .line {
        height: 16px;
        width: 0px;
        opacity: 1;
        border: 1px solid #ffffff;
        margin-left: 2.125rem;
        margin-right: 2.125rem;
      }
    }
    .bototm {
      color: #ffffff;
      margin-top: -0.0625rem;
    }
  }
}
</style>
