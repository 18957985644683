<!--
* @description 滚动到页面的顶部
* @fileName up-top.vue
* @author liulian
* @date 2022/06/16 14:45:14
-->
<template>
  <div class="upTop">
    <el-backtop>
        <img class="backtop-img" src="@/assets/images/uptop@2x.png" alt="">
    </el-backtop>
  </div>
</template>

<script>
export default {
  name: 'up-top',
  components: {},
  data() {
    return {

    }
  },
  computed: {},
  created() { },
  mounted() { },
  methods: {}
}
</script>

<style scoped lang="scss">
.upTop {
    ::v-deep .el-backtop {
       right: 200px !important;
       top: 37.125rem !important;
       border-radius: 0;
       background-color: transparent !important;
       box-shadow:0 0 0 0 !important
    }
    .backtop-img {
        width: 100%;
        height: 100%;
    }
}
</style>
