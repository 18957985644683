<!--
* @description 便签按钮
* @fileName tag-btn.vue
* @author liulian
* @date 2022/06/13 11:20:42
-->
<template>
  <div class="tag">
    <div class="btn" :class="config.type === 'red' ? 'red' : 'green'">
      <img class="tag_icon" v-if="config.type === 'red'" src="@/assets/images/home/V@2x.png" />
      <span class="text">{{ config.text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "tag-btn",
  components: {},
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          type: "red",
          text: "初级",
        }
      },
    },
  },
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="scss">
.tag {
  .btn {
    min-width: 2.625rem;
    height: 1.0625rem;
    border-radius: 1.5625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0.1875rem;
    padding-right: 0.1875rem;
    img {
      width: 0.4963rem;
      height: 0.5313rem;
      margin-right: 0.125rem;
    }
    span {
      white-space: nowrap;
      font-size: 0.75rem;
      font-weight: 400;
    }
    .tag_icon {
      width: 0.4963rem;
    height: 0.5313rem;

    }
  }
  .red {
    background: #ffeff0;
    span {
      color: #dc2b2b;
    }
  }
  .green {
    background: #def2e6;
    span {
      color: #11845b;
    }
  }
}
</style>
