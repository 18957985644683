import request from "@/utils/request.js"

// 账号注册-官方
export function registerOfficialApi(data) {
  return request({
    url: "/ph/api/auth/account/register/official",
    method: "post",
    data,
  })
}
// 账号注册-个人
export function registerPersonApi(data) {
  return request({
    url: "/ph/api/auth/account/register/person",
    method: "post",
    data,
  })
}
// 账号登录-密码登录
export function loginPwdApi(data) {
  return request({
    url: "/ph/api/auth/login/pwd",
    method: "post",
    data,
  })
}
// 账号登录-验证码登录
export function loginCodeApi(data) {
  return request({
    url: "/ph/api/auth/login/code",
    method: "post",
    data,
  })
}
// 账号密码-忘记密码
export function passwordResetApi(data) {
  return request({
    url: "/ph/api/auth/account/password/reset",
    method: "post",
    data,
  })
}

// 获取用户信息
export function getUserApi() {
  return request({
    url: "/ph/api/user/userInfo",
    method: "get",
  })
}

// 标签列表
export function getTagListApi() {
  return request({
    url: "/ph/api/common/tagList/search",
    method: "get",
  })
}

// 成为营销师，角色列表
export function getRoleListApi() {
  return request({
    url: "/ph/api/marketer/roleList",
    method: "get",
  })
}
// 成为营销师标签列表
export function getTagOperateApi() {
  return request({
    url: "/ph/api/common/tagList/operate",
    method: "get",
  })
}
// 成为营销师
export function becomeMarketerApi(data) {
  return request({
    url: "/ph/api/marketer/become",
    method: "post",
    data,
  })
}

// 发布需求
export function replaseDemand(data) {
  return request({
    url: "/ph/api/demand/add",
    method: "post",
    data,
  })
}
// 我的需求
export function myDemanList(data) {
  return request({
    url: "/ph/api/demand/myDemandList",
    method: "get",
    params: data,
  })
}
// 成功需求详情
export function myDemandDetail(id) {
  return request({
    url: "/ph/api/demand/detail/" + id,
    method: "get",
  })
}
// 需求列表审核
export function UnderDemandList(data) {
  return request({
    url: "/ph/api/demand/myDemandAuditList",
    method: "get",
    params: data,
  })
}
// 编辑需求
export function editDamandList(id) {
  return request({
    url: "/ph/api/demand/editInfo/" + id,
    method: "get",
  })
}
// 更新需求
export function uploadDamandList(data) {
  return request({
    url: "/ph/api/demand/update",
    method: "post",
    data,
  })
}
// 重新提交审核
export function exaimsubmit(data) {
  return request({
    url: "/ph/api/demand/resubmit",
    method: "post",
    data,
  })
}
// 需求审核详情add
export function exaimDeamd(id) {
  return request({
    url: "/ph/api/demand/auditInfo/" + id,
    method: "get",
  })
}
// 删除需求
export function deleteDamandList(id) {
  return request({
    url: "/ph/api/demand/delete/" + id,
    method: "delete",
  })
}
// 我的报名列表
export function signList(data) {
  return request({
    url: "/ph/api/demand/joinDemandList",
    method: "get",
    params: data,
  })
}
// 参与报名
export function signUp(data) {
  return request({
    url: "/ph/api/demand/joinDemand",
    method: "post",
    data,
  })
}
// 取消报名
export function cancle(id) {
  return request({
    url: "/ph/api/demand/cancelDemandJoin/" + id,
    method: "delete",
  })
}
// 操作页面标签列表
export function getTags() {
  return request({
    url: "/ph/api/common/tagList/operate",
    method: "get",
  })
}
// wps登录
export function getWpsTokenApi() {
  return request({
    url: "/ph/api/auth/login/wps",
    method: "post",
  })
}
// 新建模板列表
export function getTemplateList() {
  return request({
    url: "/ph/api/case/templateList",
    method: "get",
  })
}
// 新建模板
export function createFileApi(data) {
  return request({
    url: "/ph/api/case/workspace/createFile",
    method: "post",
    data,
  })
}
// 最近编辑的模板
export function recentFileListApi() {
  return request({
    url: "/ph/api/case/workspace/recentFileList",
    method: "get",
  })
}
// 我的创作
export function allFileListApi(data) {
  return request({
    url: "/ph/api/case/workspace/allFileList",
    method: "get",
    params: data,
  })
}
// 删除案例
export function deleteFileApi(id) {
  return request({
    url: `/ph/api/case/workspace/deleteFile/${id}`,
    method: "delete",
  })
}
// 编辑地址
export function fileEditorUrlApi(id) {
  return request({
    url: `/ph/api/case/workspace/fileEditorUrl/${id}`,
    method: "get",
  })
}
// 搜索案例营销师
export function marketerListApi(data) {
  return request({
    url: `/ph/api/common/search/marketerList`,
    method: "post",
    data,
  })
}

// 搜索列表需求大厅
export function demandListApi(data) {
  return request({
    url: `/ph/api/common/search/demandList`,
    method: "post",
    data,
  })
}
// 需求大厅详情
export function demandDetailApi(id) {
  return request({
    url: `/ph/api/demand/detail/${id}`,
    method: "get",
  })
}

// 新增案例
export function addCase(data) {
  return request({
    url: "/ph/api/case/add",
    method: "post",
    data,
  })
}
// 我的案例列表
export function caseList(data) {
  return request({
    url: "/ph/api/case/myCaseList",
    method: "get",
    params: data,
  })
}
// 我的案例审核列表
export function exaimCseList(data) {
  return request({
    url: "/ph/api/case/myCaseAuditList",
    method: "get",
    params: data,
  })
}
// 搜索列表案例
export function caseListApi(data) {
  return request({
    url: "/ph/api/common/search/caseList",
    method: "post",
    data,
  })
}
// 找案例详情
export function caseDetailApi(id) {
  return request({
    url: `/ph/api/case/detail/${id}`,
    method: "get",
  })
}
// 案例详情
export function caseDetail(id) {
  return request({
    url: "/ph/api/case/detail/" + id,
    method: "get",
  })
}
// 案例编辑信息
export function caseEdit(id) {
  return request({
    url: "/ph/api/case/editInfo/" + id,
    method: "get",
  })
}
// 案例审核信息
export function exaimCase(id) {
  return request({
    url: "/ph//api/case/auditInfo/" + id,
    method: "get",
  })
}
// 编辑案例
export function editCase(data) {
  return request({
    url: "/ph/api/case/update",
    method: "post",
    data,
  })
}
// 重新提交案例审核
export function againCase(data) {
  return request({
    url: "/ph/api/case/resubmit",
    method: "post",
    data,
  })
}
// 删除案例
export function delCase(id) {
  return request({
    url: "/ph/api/case/delete/" + id,
    method: "delete",
  })
}
// 行业热搜词
export function hotKeywordListApi(params) {
  return request({
    url: "/ph/api/common/search/hotKeywordList",
    method: "get",
    params,
  })
}
// 我的购买
export function buyFileListApi(data) {
  return request({
    url: "/ph/api/case/workspace/buyFileList",
    method: "get",
    data,
  })
}
// 爆款案例
export function recommendedListApi() {
  return request({
    url: "/ph/api/case/recommendedList",
    method: "get",
  })
}
// 用户主页详情
export function userPageDetailApi(id) {
  return request({
    url: `/ph/api/user/userPageDetail/${id}`,
    method: "get",
  })
}
// 官方，机构下属用户
export function userListApi(id) {
  return request({
    url: `/ph/api/official/userList/${id}`,
    method: "get",
  })
}

// 我的文章列表
export function articleList(data) {
  return request({
    url: "/ph/api/article",
    method: "get",
    params: data,
  })
}
// 新建文章
export function addArticle(data) {
  return request({
    url: "/ph/api/article",
    method: "post",
    data,
  })
}
// 文章详情
export function articleDetail(id) {
  return request({
    url: "/ph/api/article/" + id,
    method: "get",
  })
}
// 编辑文章
export function editArticle(id, data) {
  return request({
    url: "/ph/api/article/" + id,
    method: "put",
    data,
  })
}
// 删除文章
export function delArticle(id) {
  return request({
    url: "/ph/api/article/" + id,
    method: "delete",
  })
}
// 用户主页案例列表
export function userPageCaseListApi(data) {
  return request({
    url: `/ph/api/case/userPageCaseList/${data.user_id}`,
    method: "get",
    params: data,
  })
}
// 新增评论
export function commentApi(data) {
  return request({
    url: `/ph/api/comment`,
    method: "post",
    data,
  })
}
// 获取评论列表
export function commentPageApi(data) {
  return request({
    url: `/ph/api/comment/page`,
    method: "get",
    params: data,
  })
}
// 新增回复
export function replyApi(data) {
  return request({
    url: `/ph/api/reply`,
    method: "post",
    data,
  })
}
// 新增收藏
export function collectApi(data) {
  return request({
    url: `/ph/api/collect`,
    method: "post",
    data,
  })
}
// 取消收藏
export function cancelCollectApi(id) {
  return request({
    url: `/ph/api/case/cancelCollect/${id}`,
    method: "delete",
  })
}
// 关注某人
export function followApi(data) {
  return request({
    url: `/ph/api/follow`,
    method: "post",
    data,
  })
}
// 我的项目列表
export function ProjectList(data) {
  return request({
    url: "/ph/api/project",
    method: "get",
    params: data,
  })
}
// 新建项目
export function addProject(data) {
  return request({
    url: "/ph/api/project",
    method: "post",
    data,
  })
}
// 项目详情
export function prodetail(id) {
  return request({
    url: "/ph/api/project/" + id,
    method: "get",
  })
}
// 修改项目状态
export function projectStatus(data) {
  return request({
    url: "/ph/api/project/setStatus",
    method: "post",
    data,
  })
}
// 创作者重新提交审核
export function Resubmit(data) {
  return request({
    url: "/ph/api/project/submit",
    method: "post",
    data,
  })
}

// 编辑项目
export function editProject(id, data) {
  return request({
    url: "/ph/api/project/" + id,
    method: "put",
    data,
  })
}
//  删除项目
export function deleteProject(id) {
  return request({
    url: "/ph/api/project/" + id,
    method: "delete",
  })
}
// 关注粉丝列表
export function followList(query) {
  return request({
    url: "/ph/api/follow/page",
    method: "get",
    params: query,
  })
}
// 取消关注
export function cancleFollow(id) {
  return request({
    url: "/ph/api/follow/" + id,
    method: "delete",
  })
}
// 收藏列表
export function collectionList(query) {
  return request({
    url: "/ph/api/collect/page",
    method: "get",
    params: query,
  })
}
// 取消收藏
export function cancleCollection(id) {
  return request({
    url: "/ph/api/collect/" + id,
    method: "delete",
  })
}
// 朋友圈列表 我的动态
export function wechart(query) {
  return request({
    url: "/ph/api/update",
    method: "get",
    params: query,
  })
}
//用户主页详情
export function userHomePage(id) {
  return request({
    url: "/ph/api/user/userPageDetail/" + id,
    method: "get",
  })
}
//  机构创作者列表
export function CreatorList(query) {
  return request({
    url: "/ph/api/official/userAllList",
    method: "get",
    params: query,
  })
}
// 机构审核列表
export function ExaimList(query) {
  return request({
    url: "/ph/api/official/userAuditList",
    method: "get",
    params: query,
  })
}
// 移除成员
export function removePeople(id) {
  return request({
    url: "/ph/api/official/removeUser/" + id,
    method: "delete",
  })
}
// 审核机构成员加入
export function exaimPeple(data) {
  return request({
    url: "/ph/api/official/auditJoin",
    method: "post",
    data,
  })
}
//是否隐藏机构下属成员
export function isShowCreator() {
  return request({
    url: "/ph/api/official/switchUserShow",
    method: "post",
  })
}
// 消息列表
export function messageApi(data) {
  return request({
    url: `/ph/api/message`,
    method: "get",
    params: data,
  })
}
// 用户设置基本信息
export function setUp() {
  return request({
    url: "/ph/api/user/baseInfo",
    method: "get",
  })
}
//保存基本信息
export function setSeetings(data) {
  return request({
    url: "/ph/api/user/updateBaseInfo",
    method: "post",
    data,
  })
}
// 修改密码
export function changePwd(data) {
  return request({
    url: "/ph/api/auth/account/password/update",
    method: "post",
    data,
  })
}

// 账号换绑
export function changeBinds(data) {
  return request({
    url: "/ph/api/auth/account/rebind",
    method: "post",
    data,
  })
}
// 底部导航
export function navigationInfoApi() {
  return request({
    url: "/ph/api/common/config/navigationInfo",
    method: "get",
  })
}
// 网站信息
export function webInfoApi() {
  return request({
    url: "/ph/api/common/config/webInfo",
    method: "get",
  })
}
// 单页面详情
export function pageDetailApi(id) {
  return request({
    url: `/ph/api/common/config/pageDetail/${id}`,
    method: "get",
  })
}
// 取消关注
export function nofollowApi(id) {
  return request({
    url: `/ph/api/follow/${id}`,
    method: "delete",
  })
}

// 根据用户id取消关注

export function cancelfollowApi(user_id) {
  return request({
    url: `/ph/api/follow/cancel/${user_id}`,
    method: "delete",
  })
}
// imH获取sim
export function getUig() {
  return request({
    url: "/ph/api/im/getUserSig",
    method: "get",
  })
}
// 提交交付物
export function subdeliverables(data) {
  return request({
    url: "/ph/api/projectProcess/submit",
    method: "post",
    data,
  })
}
// 修改阶段状态
export function editstagestatus(data) {
  return request({
    url: "/ph/api/projectProcess/setStatus",
    method: "post",
    data,
  })
}

// 订单支付
export function createOrderApi(data) {
  return request({
    url: "/ph/api/case/createOrder",
    method: "post",
    data,
  })
}

// 案例订单列表
export function orderListApi(data) {
  return request({
    url: `/ph/api/case/orderList/${data.type}`,
    method: "get",
    params: data,
  })
}

// 钱包信息
export function wallet() {
  return request({
    url: "/ph/api/wallet/info",
    method: "get",
  })
}
// 钱包明细
export function walletDetails(id, data) {
  return request({
    url: "/ph/api/wallet/transactionRecordList/" + id,
    method: "get",
    data,
  })
}
// 上传文件到oss
export function uploadOssApi(data) {
  return request({
    url: "/ph/api/case/workspace/getOssPolicyByWorkSpaceFile",
    method: "get",
    params: data,
  })
}
// oss直传获取回调
export function oss(params) {
  return request({
    url: "/ph/api/case/workspace/getOssPolicyByWorkSpaceFile",
    method: "get",
    params,
  })
}

// 项目评分
export function projectscroll(data) {
  return request({
    url: "/ph/api/project/score",
    method: "post",
    data,
  })
}
// 消息统计
export function countMessageApi() {
  return request({
    url: "/ph/api/countMessage",
    method: "get",
  })
}
//  修改支付宝账号
export function editzfb(data) {
  return request({
    url: "/ph/api/auth/account/updateAliPayAccount",
    method: "post",
    data,
  })
}
// im 同步
export function updataIm() {
  return request({
    url: "/ph/api/im/test",
    method: "get",
  })
}
// 机构创作者项目
export function creatorProject(params) {
  return request({
    url: "/ph/api/project/official",
    method: "get",
    params,
  })
}
// 是否收藏
export function iscollect(params) {
  return request({
    url: "/ph/api/collect/cancel",
    method: "get",
    params,
  })
}
// 提现
export function withdrawal(data) {
  return request({
    url: "/ph/api/wallet/withdraw",
    method: "post",
    data,
  })
}
// 阿里wps测试
export function getWebOfficeURLApi(id) {
  return request({
    url: `/ph/api/case/workspace/getWebOfficeURL/${id}`,
    method: "get",
  })
}
// 刷新token
export function refreshWebOfficeTokenApi(data) {
  return request({
    url: "/ph/api/case/workspace/refreshWebOfficeToken",
    method: "post",
    data,
  })
}
// 未读消息置为已读
export function clearCountApi(params) {
  return request({
    url: "/ph/api/message/clearCount",
    method: "get",
    params,
  })
}
// 评论删除
export function delCommontAPi(id) {
  return request({
    url: `/ph/api/comment/${id}`,
    method: "delete",
  })
}
// 回复删除
export function delReply(id) {
  return request({
    url: `/ph/api/reply/${id}`,
    method: "delete",
  })
}
// 文件重命名
export function renameFileApi(data) {
  return request({
    url: `/ph/api/case/workspace/renameFile`,
    method:'post',
    data
  })
}
// 文件保存回调
export function immSaveCallBackApi(data) {
  return request({
    url: `/ph/api/case/workspace/immSaveCallBack`,
    method:'post',
    data
  })
}
