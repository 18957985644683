<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 * 
 * 
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * 
 *            佛祖保佑     永不宕机     永无BUG
 * 
 *        佛曰:  
 *                写字楼里写字间，写字间里程序员；  
 *                程序人员写程序，又拿程序换酒钱。  
 *                酒醒只在网上坐，酒醉还来网下眠；  
 *                酒醉酒醒日复日，网上网下年复年。  
 *                但愿老死电脑间，不愿鞠躬老板前；  
 *                奔驰宝马贵者趣，公交自行程序员。  
 *                别人笑我忒疯癫，我笑自己命太贱；  
 *                不见满街漂亮妹，哪个归得程序员？
 -->



<!--
 * @Author: 任重浩
 * @Date: 2022-07-27 14:59:14
 * @LastEditors: 任重浩
 * @LastEditTime: 2022-08-11 11:03:21
 * @Description: 
 * @FilePath: /social_contact_pc/src/views/grzx/sz/basicsettings/settings.vue
-->
 
<template>
    <div class="setting" id="setting">
        <!-- {{$store.state.userInfo }} -->
        <el-form :model="userform" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="头像" prop="avatar" style="height:5rem">
                <el-upload :action="uploadImg" :before-upload="beforeupload" :on-success="handleRemove"
                    :show-file-list=false :headers="importHeaders">
                    <img :src="userform.avatar" alt="">
                </el-upload>
            </el-form-item>
            <el-form-item
                :label="userform.official_type == 2 && userform.is_official_admin == 1 ? '品牌名' : '' || userform.official_type == 1 && userform.is_official_admin == 1 ? '机构名' : '用户名'"
                prop="username">
                <el-input v-model="userform.username"></el-input>
            </el-form-item>
            <el-form-item
                :label="userform.official_type == 1 || userform.official_type == 0 || userform.official_type == null ? '机构' : '' || userform.official_type == 2 ? '品牌' : ''"
                id="mechanism"
                v-if="official_data.join_audit_status == 0 || official_data.join_audit_status == -1 || official_data.join_audit_status == 1 && userform.official_type == 1">
                <el-input v-model="userform.unique_code" placeholder="请输入数字英文混合的六位数唯一码"
                    v-if="official_data.join_audit_status == -1">
                </el-input>
                <el-input v-model="userform.join_official_name" disabled v-if="official_data.join_audit_status == 0">
                </el-input>
                <el-input v-model="official.unique_code" disabled v-if="userform.is_official_admin==1 && official_data.join_audit_status == 1">
                </el-input>
                <el-input v-model="userform.official_name" disabled v-if="userform.is_official_admin==0 && official_data.join_audit_status == 1">
                </el-input>
                <p v-if="official_data.join_audit_status == 0">审核中..</p>
            </el-form-item>
           
             <el-form-item label="机构联系人姓名" prop="username"  v-if="$store.state.userInfo && $store.state.userInfo.is_official_admin === 1 && $store.state.userInfo.official_type==1">
                <el-input v-model="official.organization_username"></el-input>
            </el-form-item>
            <el-form-item label="机构联系人电话" prop="username"   v-if="$store.state.userInfo && $store.state.userInfo.is_official_admin === 1 && $store.state.userInfo.official_type==1">
                <el-input v-model="official.organization_mobile"></el-input>
            </el-form-item>
            <!-- 循环展示tags标签 -->
            <div v-for="(item, index) in taglist" :key="index">
                <div class="tagslist" v-if="item.bind == 'area'">
                    <p class="name">{{ item.name }}</p>
                    <div>
                        <div class="tags-box" v-for="(item1, index1) in item.children" :key="index1">
                            <p>{{ item1.name }}</p>
                            <div class="tags">
                                <ul>
                                       <!-- :class="item2.isActive == true ? 'isactive' : ''" -->
                                    <li v-for="(item2, index2) in item1.children" :key="index2"
                                            :class="pathActive==item2.id?'isactive':''"
                                        @click="onTag(item2, item.bind, item.children)">
                                        {{ item2.name }}
                                    </li>
                                    <li class="custom" @click="onCustom(item1, item.children, item1.bind)"
                                        v-if="!item1.onInput">
                                        <span>自定义</span><i class="el-icon-plus"></i>
                                    </li>
                                    <li v-else class="cusinp">
                                        <el-input v-model="tagslist.contentvalue"
                                            @blur="onInputBlur(tagslist.contentvalue, item1.bind)">
                                        </el-input>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tagslist" style="alignItems:{item.bind == 'industry'|| item.bind == 'social?'flexStart':''}"
                    v-if="item.bind == 'industry' || item.bind == 'social'">
                    <p class="name" style="margin-top:20px">{{ item.name }}</p>
                    <div class="tags-box">
                        <div class="tags" style="margin-bottom:0px">
                            <ul>
                                <li v-for="(i, index) in item.children" :key="index"
                                    :class="i.isActive == true ? 'isactive' : ''"
                                    @click="onTag(i, item.bind, item.children)">
                                    {{ i.name }}
                                </li>
                                <li class="custom" @click="onCustomtwo(item, item.children, item.bind)"
                                    v-if="!item.onInput">
                                    <span>自定义</span><i class="el-icon-plus"></i>
                                </li>
                                <li v-else class="cusinp">
                                    <el-input v-model="tagslist.socialvalue"
                                        @blur="onInputBlur(tagslist.socialvalue, item.bind)">
                                    </el-input>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <el-form-item
                :label="userform.official_type == 2 && userform.is_official_admin == 1 ? '品牌介绍' : '' || userform.official_type == 1 && userform.is_official_admin == 1 ? '机构介绍' : '自我介绍'"
                id="textarea">
                <el-input type="textarea" id="product" placeholder="请输入自我介绍" v-model="userform.introduction"
                    maxlength="200" show-word-limit>
                </el-input>
            </el-form-item>
            <!-- <el-form-item label="品牌介绍" id="textarea">
                <el-input type="textarea" id="product" placeholder="请输入品牌介绍" v-model="userform.text" maxlength="200"
                    show-word-limit>
                </el-input>
            </el-form-item> -->
            <el-form-item id="sub">
                <el-button @click="submit">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
var mytoken = localStorage.getItem('token')
import { getTags, setSeetings, userHomePage, oss } from "@/api/api"
import { json } from "body-parser"
export default {
    props: {
        userinfor: {
            type: Object,
            default: ""
        },

    },
    data() {
        return {
            pathActive:"",
            datas: {},
            host: "",
            dir: "",
            uploadImg: '/ja/upload/oss/img',
            importHeaders: { Authorization: mytoken },
            user_data: {},//个人基本信息
            taglist: [],//标签列表
            tag_id_arr: [],
            callback: "",
            tagsindex: null,
            flaginput1: false,
            flaginput2: false,
            flaginput3: false,
            flaginput4: false,
            flaginput5: false,
            tagslist: {
                contentvalue: "",
                implevalue: "",
                flowvalue: "",
                socialvalue: "",
                industryvalue: "",
            },
            userform: {
            },
            official_data: {},//判断是否加入机构
            rules: {},
            inputVisible: false,
            inputValue: '',
            official:"",
            arrone:[],
            arrtwo:[],
            arrthree:[],

        }
    },
    watch: {
        userinfor: {
            handler(data) {
                if (data) {
                    this.userform = data.user_data
                    this.official=data.official_data
                    this.userform.join_official_name = data.official_data.join_official_name
                    this.official_data.join_audit_status = data.official_data.join_audit_status
                    if (data.tag_data.length > 0) {
                        this.tag_id_arr = data.tag_data
                        console.log(this.tag_id_arr)
                        this.getTags()
                    }

                }
            }
        }
    },
    mounted() {

    },
    methods: {
        /**
         * @Author: 任重浩
         * @Date: 2022-08-11 11:03:09
         * @description: 获取标签
         * @return {*}
         */

        getTags() {
            getTags()
                .then(res => {
                    if (res.code == 10000) {
                        // console.log(res.data)
                        res.data.map(e => {
                            e.children.map(ch1 => {
                                // 判断有无children属性
                                if (ch1.hasOwnProperty("children") == true) {
                                    ch1.children.map(ch2 => {
                                        console.log(this.tag_id_arr.includes(ch2.id))
                                        if (this.tag_id_arr.includes(ch2.id) == true) {
                                            this.pathActive=ch2.id
                                            console.log(ch2)
                                            ch2.isActive = true
                                        } else {
                                            ch2.isActive = false
                                        }

                                        ch2.custorm = 0
                                    })
                                } else {

                                    if (this.tag_id_arr.includes(ch1.id) == true) {
                                        console.log(ch1)
                                        ch1.isActive = true
                                    } else {
                                        ch1.isActive = false
                                    }


                                    ch1.custorm = 0

                                }
                            })
                        })
                        this.taglist = res.data
                        this.checkItem()
                        console.log(this.taglist)
                    }
                })
        },
        // 标签选中
        onTag(item, tagname, arr) {
            if(tagname=='area'){
                 this.pathActive=item.id
            }
            let arrnum = []
            let industryNum = []
            let socailNum = []
            this.$set(item, 'isActive', !item.isActive)
            if (tagname == "area") {
                if(this.pathActive==item.id){
                    arrnum.push(item)
                   
                }
              
            } else if (tagname == 'industry') {
                arr.map(e => {
                    if (e.isActive == true) {
                        industryNum.push(e)
                    }
                })
            } else if (tagname == "social") {
                arr.map(e => {
                    if (e.isActive == true) {
                        socailNum.push(e)
                    }
                })
            }
            
            this.arrone=arrnum
            this.arrtwo=industryNum
            this.arrthree=socailNum
            if (industryNum.length > 5 || socailNum.length > 5) {
                this.$message.error("最多只能选5个")
                this.$set(item, 'isActive', false)
                return
            } else {
                this.checkItem()
            }
        },
        checkItem() {
            var arr = []
            var custom = []
            this.taglist.map(e => {
                e.select = []
                e.children.map(item => {
                    if (item.hasOwnProperty("children") == true) {
                      console.log(e)
                        item.children.map(i => {
                            // (i.custorm == 0 && i.isActive == true
                            if(e.bind=='area' && this.pathActive==i.id){
                                this.arrone.push(i)
                            }
                            if (this.pathActive==i.id){
                                arr.push(i.id)
                                if(e.bind=='area'){
                                this.pathActive=i.id
                            }
                            } else if (i.custorm == 1 && i.isActive == true) {
                                custom.push({
                                    parent_bind: i.bind,
                                    name: i.name
                                })
                            }
                        })
                    } else {
                        if(e.bind=='social' && item.custorm == 0 && item.isActive == true ){
                            this.arrtwo.push(item)
                        }
                        if(e.bind=='industry' && item.custorm == 0 && item.isActive == true ){
                            this.arrthree.push(item)
                        }
                        if (item.custorm == 0 && item.isActive == true) {
                            arr.push(item.id)
                        } else if (item.custorm == 1 && item.isActive == true) {
                            custom.push({
                                parent_bind: item.bind,
                                name: item.name
                            })
                        }
                    }

                })
            })
            console.log(arr)
            this.userform.common_tag_arr=arr
            this.userform.custom_tag_arr = custom
        },
        onCustom(item, children, bind) {

            let arr = children
            arr.map(e => {
                this.$set(e, 'onInput', e.bind === bind)
            })
        },
        onCustomtwo(item, children, bind) {
            if (item.bind == bind) {
                this.$set(item, 'onInput', true)
            } else {
                this.$set(item, 'onInput', false)
            }
        },
        // 自定义tag标签
        onInputBlur(tagname, bind) {
            console.log(tagname, bind)
            this.taglist.map(e => {
                if (e.bind == "area") {
                    e.children.map(i => {
                        if (i.onInput == true) {
                            if (tagname != "") {
                                i.children.push({
                                    isActive: false,
                                    name: tagname,
                                    custorm: 1,
                                    bind: bind

                                })
                            }
                            this.$set(i, 'onInput', false)
                            this.tagslist.contentvalue = ""
                        }
                    })
                    console.log(this.taglist)
                } else {
                    if (e.onInput == true) {
                        console.log(tagname)
                        if (tagname != "") {
                            console.log(e.children)
                            e.children.push({
                                isActive: false,
                                name: tagname,
                                custorm: 1,
                                bind: bind
                            })
                            this.$set(e, 'onInput', false)
                            this.tagslist.socialvalue = ""
                        }

                    }

                }
            })
        },
        // 保存
        submit() {
          if(this.tag_id_arr.length>0){
             if(this.arrone.length==0){
                this.$message.warning('请选择传播内容')
                return false;
            }else if(this.arrtwo.length==0){
                this.$message.warning('请选择社交网络')
                return false;
            }else if(this.arrthree.length==0) {
                this.$message.warning('请选择行业领域')
                return false;
            }
          }
            if (this.userform.unique_code == undefined) {
                this.userform.unique_code = ""
            }
            var data={}
            if(this.userform.official_type == 1) {
                // 仅机构下才传值
                data.avatar=this.userform.avatar
                data.name=this.userform.username
                data.unique_code=this.userform.unique_code
                data.common_tag_arr=this.userform.common_tag_arr
                data.custom_tag_arr=this.userform.custom_tag_arr
                data.introduction=this.userform.introduction
                data.organization_mobile=this.official.organization_mobile
                 data.organization_username=this.official.organization_username
            }else {
                 data.avatar=this.userform.avatar
                data.name=this.userform.username
                data.unique_code=this.userform.unique_code
                data.common_tag_arr=this.userform.common_tag_arr
                data.custom_tag_arr=this.userform.custom_tag_arr
                data.introduction=this.userform.introduction
            }
            setSeetings(data)
                .then(res => {
                    if (res.code == 10000) {
                        console.log(res)
                        this.$message.success(res.message)
                        this.$emit("upload")
                    }
                })
        },
        // 头像上传成功
        handleRemove(res) {
            console.log('+++', res)
            if (res.code == 10000) {
                this.userform.avatar = res.data
            }
        },

        // 上传之前
        beforeupload(file) {
            if (file.type !== "image/png" && file.type !== "image/jpeg") {
                this.$message.error("仅支持上传jpg/png格式的文件");
            }
            return file.type === "image/png" || file.type === "image/jpeg";
        },
        // / 产生不重复的随机数 mynum 产生数量  mywei 生成位数 callback 回调函数
        bossRand(mynum, mywei, callback) {
            // var num=""
            // this.bossRand(5,1,function(arr){num=arr.toString().replace(/,/g, "")+Math.random().toString(36).substr(2)})
            var RandomArr = [];
            var RandomTotal = 0;
            function createRandom(num, wei) {
                if (RandomArr.length == 0) {
                    RandomTotal = num;
                }
                var start = "1";
                var end = "9";
                var wint = parseInt(wei);
                for (var i = 1; i < wint; i++) {
                    start += "0"
                    end += "0";
                }
                for (var i = 0; i < parseInt(num); i++) {
                    var code = parseInt(Math.random() * parseInt(end) + parseInt(start));
                    var str = "," + RandomArr.toString() + ",";
                    if (str.indexOf("," + code + ",") == -1) {
                        RandomArr.push(code);
                    }
                }
                //Remove duplication
                var cha = RandomTotal - RandomArr.length;
                if (cha <= 0) {
                    callback(RandomArr)
                    RandomArr = [];
                    RandomTotal = 0;
                    return;
                } else {
                    createRandom(cha, wei)
                }
            }
            createRandom(mynum, mywei);
        },

        form() {

        },
        handleClose(tag) {
            this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
        },

        showInput() {
            this.inputVisible = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },

        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                this.dynamicTags.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = '';
        }

    }
}
</script>
<style lang="scss" >
#setting {
    .el-form {
        .el-form-item {
            display: flex;
            margin: 1rem 0;
            align-items: center;

            .el-input {
                width: 22.5rem;
                background: #FFFFFF;
                box-shadow: 0px .25rem .625rem .0625rem rgba(58, 114, 255, 0.06);
                border-radius: .625rem .625rem .625rem .625rem;

                .el-input__inner {
                    font-weight: 100;
                    font-size: 16px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    line-height: 19px;
                    color: #111029;

                    &::placeholder {
                        font-weight: 100;
                        color: #A0A3BD;
                    }
                }
            }

            .tle {
                width: 5rem;
                height: 2.625rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #EFF0F6;
                border-radius: .25rem .25rem .25rem .25rem;
                font-size: 1rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #57596C;
                line-height: 1.1875rem;
                margin-bottom: 1rem;
            }


            .el-form-item__label {
                // width: 70px !important;
                text-align: right;
                white-space: nowrap;
                color: #111029;
                font-weight: 100;
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
            }

            .el-form-item__content {
                height: 100%;
                margin-left: 2rem !important;

                .tags-box {
                    p {
                        width: 5rem;
                        height: 2.625rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 1rem;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #57596C;
                        background: #EFF0F6;
                        border-radius: .25rem .25rem .25rem .25rem;

                    }

                    .tags {
                        margin-bottom: 1.5rem;

                        ul {
                            display: flex;
                            flex-wrap: wrap;

                            li {
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: auto;
                                padding: 0 1rem;
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #57596C;
                                line-height: 1.1875rem;
                                margin: 1rem .25rem 0;
                                white-space: nowrap;
                                height: 2.625rem;
                                background: #FFFFFF;
                                border-radius: 2rem;
                                border: .0625rem solid #DCDDEB;
                            }

                            .custom {
                                span {
                                    display: block;
                                    margin-right: 1rem;
                                }
                            }

                            .cusinp {
                                min-width: 6.875rem;

                                .el-input {
                                    width: 6.875rem;
                                    min-width: 6.875rem;
                                    border: none;
                                    box-shadow: none;

                                    .el-input__inner {
                                        border: none;
                                        box-shadow: none;
                                        width: 6.875rem;
                                        min-width: 6.875rem;
                                    }
                                }
                            }

                            .isactive {
                                border: 1px solid #2F2C42;
                                color: #2F2C42;
                            }
                        }

                    }

                    .el-tag {
                        display: inline-block;
                        height: 2.625rem;
                        padding: .625rem 1rem;
                        background: #FFFFFF;
                        border-radius: 2rem 2rem 2rem 2rem;
                        border: .0625rem solid #DCDDEB;
                        margin: 1rem 0 1rem .625rem;
                        font-size: 1rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #57596C;
                        line-height: 1.1875rem;
                    }


                    .el-button {
                        font-size: 1rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #57596C;
                        line-height: .75rem;
                        padding: .625rem 1rem;
                        background: #FFFFFF;
                        border-radius: 2rem 2rem 2rem 2rem;
                        border: 1px solid #DCDDEB;
                    }

                    .button-new-tag {
                        margin-top: 1rem;
                        margin-left: .625rem;
                        margin-bottom: 1rem;
                        padding: .625rem 1rem;
                        height: 2.625rem;
                        line-height: 1.375rem;
                        background: #FFFFFF;
                        border-radius: 32px 32px 32px 32px;
                        border: 1px solid #2F2C42;
                        font-size: 1rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #57596C;
                        line-height: 1.1875rem;
                    }

                    .input-new-tag {
                        max-width: 120px;
                        margin-left: 10px;
                        margin-top: 1rem;

                        .el-input__inner {
                            padding: .625rem 1rem;
                            height: 2.625rem;
                            line-height: 1.375rem;
                            background: #FFFFFF;
                            border-radius: 32px 32px 32px 32px;
                            border: 1px solid #2F2C42;
                            margin-bottom: 1rem;
                        }
                    }
                }
            }

            img {
                display: block;
                width: 5rem;
                height: 5rem;
                border-radius: 50%;
            }

            .el-tag {
                margin-left: 10px;
                padding: .625rem 1rem;
                height: 2.625rem;
                line-height: 1.375rem;
                background: #FFFFFF;
                border-radius: 32px 32px 32px 32px;
                border: 1px solid #2F2C42;
                margin-bottom: 1rem;
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #57596C;
                line-height: 1.1875rem;

            }

            .button-new-tag {
                margin-left: 10px;
                margin-bottom: 1rem;
                padding: .625rem 1rem;
                height: 2.625rem;
                line-height: 1.375rem;
                background: #FFFFFF;
                border-radius: 32px 32px 32px 32px;
                border: 1px solid #2F2C42;
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #57596C;
                line-height: 1.1875rem;
            }

            .input-new-tag {
                max-width: 120px;
                margin-left: 10px;

                .el-input__inner {
                    padding: .625rem 1rem;
                    height: 2.625rem;
                    line-height: 1.375rem;
                    background: #FFFFFF;
                    border-radius: 32px 32px 32px 32px;
                    border: 1px solid #2F2C42;
                    margin-bottom: 1rem;
                }
            }
        }

        .tagslist {
            display: flex;
            align-items: flex-start;

            .name {
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #111029;
                line-height: 1.1875rem;
                position: relative;
                white-space: nowrap;

                &::before {
                    content: "*";
                    color: red;
                    position: absolute;
                    right: -10px;
                    top: -0.3125rem;
                }
            }

            .tags-box {
                margin-left: 1.875rem;

                p {
                    width: 5rem;
                    height: 2.625rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #57596C;
                    background: #EFF0F6;
                    border-radius: .25rem .25rem .25rem .25rem;

                }

                .tags {
                    margin-bottom: 1.5rem;

                    ul {
                        display: flex;
                        flex-wrap: wrap;

                        li {
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: auto;
                            padding: 0 1rem;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #57596C;
                            line-height: 1.1875rem;
                            margin: 1rem .25rem 0;
                            white-space: nowrap;
                            height: 2.625rem;
                            background: #FFFFFF;
                            border-radius: 2rem;
                            border: .0625rem solid #DCDDEB;
                        }

                        .custom {
                            span {
                                display: block;
                                margin-right: 1rem;
                            }
                        }

                        .cusinp {
                            min-width: 6.875rem;

                            .el-input {
                                width: 6.875rem;
                                min-width: 6.875rem;
                                border: none;
                                box-shadow: none;

                                .el-input__inner {
                                    border: none;
                                    box-shadow: none;
                                    width: 6.875rem;
                                    min-width: 6.875rem;
                                }
                            }
                        }

                        .isactive {
                            border: 1px solid #2F2C42;
                            color: #2F2C42;
                        }
                    }

                }

                .el-tag {
                    display: inline-block;
                    height: 2.625rem;
                    padding: .625rem 1rem;
                    background: #FFFFFF;
                    border-radius: 2rem 2rem 2rem 2rem;
                    border: .0625rem solid #DCDDEB;
                    margin: 1rem 0 1rem .625rem;
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #57596C;
                    line-height: 1.1875rem;
                }


                .el-button {
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #57596C;
                    line-height: .75rem;
                    padding: .625rem 1rem;
                    background: #FFFFFF;
                    border-radius: 2rem 2rem 2rem 2rem;
                    border: 1px solid #DCDDEB;
                }

                .button-new-tag {
                    margin-top: 1rem;
                    margin-left: .625rem;
                    margin-bottom: 1rem;
                    padding: .625rem 1rem;
                    height: 2.625rem;
                    line-height: 1.375rem;
                    background: #FFFFFF;
                    border-radius: 32px 32px 32px 32px;
                    border: 1px solid #2F2C42;
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #57596C;
                    line-height: 1.1875rem;
                }

                .input-new-tag {
                    max-width: 120px;
                    margin-left: 10px;
                    margin-top: 1rem;

                    .el-input__inner {
                        padding: .625rem 1rem;
                        height: 2.625rem;
                        line-height: 1.375rem;
                        background: #FFFFFF;
                        border-radius: 32px 32px 32px 32px;
                        border: 1px solid #2F2C42;
                        margin-bottom: 1rem;
                    }
                }
            }
        }

        #textarea {
            align-items: flex-start;

            .el-textarea {
                width: 57.75rem;
                height: 6.25rem;
                background: #FFFFFF;
                box-shadow: 0px 4px 10px 1px rgba(58, 114, 255, 0.06);
                border-radius: 10px 10px 10px 10px;
                opacity: 1;
                border: 1px solid #EFF0F6;

                .el-textarea__inner {
                    height: 100%;
                    font-weight: 100;
                    font-size: 16px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    line-height: 19px;
                    color: #111029;

                    &::placeholder {
                        font-weight: 100;
                        color: #A0A3BD;
                    }
                }
            }
        }

        #mechanism {
            .el-form-item__content {
                display: flex;
                align-items: center;

                p {
                    margin-left: 1rem;
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #DC2B2B;
                    line-height: 1.1875rem;
                }
            }
        }

        #sub {
            margin-top: 4.5rem;
            display: flex;
            justify-content: center;

            .el-button {
                width: 5rem;
                height: 3.625rem;
                background: #111029;
                border-radius: 3.25rem 3.25rem 3.25rem 3.25rem;
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #FFFFFF;
                line-height: 1.1875rem;
            }
        }
    }
}
</style>