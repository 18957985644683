import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: () => import(/* webpackChunkName: "about" */ "../views/home/index.vue"),
  },
  // 首页更多页面
  {
    path: "/gd",
    name: "Gd",
    component: () => import(/* webpackChunkName: "about" */ "../views/home/children/more.vue"),
  },
  // 首页简介
  {
    path: "/keyYJj",
    name: "KeyYJj",
    component: () => import("../views/home/children/keyYJj..vue"),
  },
  // 注册
  {
    path: "/register",
    name: "Register",
    component: () => import(/* webpackChunkName: "about" */ "../views/login/register.vue"),
  },
  // 忘记密码
  {
    path: "/forgotPassword",
    name: "ForgotPassword",
    component: () => import(/* webpackChunkName: "about" */ "../views/login/forgotPassword.vue"),
  },
  // 登录

  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "about" */ "../views/login/login.vue"),
  },
  // 找爆款案例
  {
    path: "/zbkal",
    name: "Zbkal",
    component: () => import(/* webpackChunkName: "about" */ "../views/zbkal/index.vue"),
  },
  {
    path: "/zbkalDetail",
    name: "ZbkalDetail",
    component: () => import(/* webpackChunkName: "about" */ "../views/zbkal/children/detail.vue"),
  },
  // 找优秀营销师
  {
    path: "/zyxyxs",
    name: "Zyxyxs",
    component: () => import(/* webpackChunkName: "about" */ "../views/zyxyxs/index.vue"),
  },
  // 机构或者个人页面
  {
    path: "/yxyxsJg",
    name: "YxyxsJg",
    component: () => import(/* webpackChunkName: "about" */ "../views/zyxyxs/children/yxyxsJg.vue"),
  },
  // 需求大厅
  {
    path: "/xqdt",
    name: "Xqdt",
    component: () => import(/* webpackChunkName: "about" */ "../views/xqdt/index.vue"),
  },
  //
  {
    path: "/xqdtDetail",
    name: "XqdtDetail",
    component: () => import(/* webpackChunkName: "about" */ "../views/xqdt/children/detail.vue"),
  },
  // 案例编辑器
  {
    path: "/albjq",
    name: "Albjq",
    component: () => import(/* webpackChunkName: "about" */ "../views/albjq/index.vue"),
  },
  // 测试阿里wps

  {
    path: "/test",
    name: "test",
    component: () => import(/* webpackChunkName: "about" */ "../views/liuliantest.vue"),
  },

  // 测试节流防抖
  {
    path: "/test2",
    name: "test2",
    component: () => import(/* webpackChunkName: "about" */ "../views/jieliufangdou.vue"),
  },
  {
    path: "/alWps",
    name: "alWps",
    component: () => import(/* webpackChunkName: "about" */ "../components/common/al-wps.vue"),
  },

  // 新建模板
  {
    path: "/addTemplate",
    name: "addTemplate",
    component: () => import(/* webpackChunkName: "about" */ "../views/albjq/children/addTemplate.vue"),
  },
  // 成为营销师
  {
    path: "/cwyxs",
    name: "Cwyxs",
    component: () => import(/* webpackChunkName: "about" */ "../views/cwyxs/index.vue"),
  },
    // 成为营销师成功
  {
    path: "/cwyxsCg",
    name: "cwyxsCg",
    component: () => import(/* webpackChunkName: "about" */ "../views/cwyxs/children/cg.vue"),
  },
  // 创建发布需求
  {
    path: "/release",
    name: "needs",
    component: () => import(/* webpackChunkName: "about" */ "../views/grzx/release/needs/needs.vue"),
  },
  // 创建项目
  {
    path: "/createproject",
    name: "createproject",
    component: () => import(/* webpackChunkName: "about" */ "../views/grzx/release/createproject/project.vue"),
  },
  // 上传案例
  {
    path: "/uploadcase",
    name: "uploadcase",
    component: () => import(/* webpackChunkName: "about" */ "../views/grzx/release/case/case.vue"),
  },
  // wps编辑器
  {
    path: "/wps",
    name: "wps",
    component: () => import(/* webpackChunkName: "about" */ "../views/wpss/index.vue"),
  },

  // 个人中心详情
  {
    path: "/grzx/detail",
    name: "grzxdetail",
    component: () => import(/* webpackChunkName: "about" */ "../components/userprofiledetail/detail.vue"),
  },
  // 项目中心详情
  {
    path: "/grzx/projectdetail",
    name: "projectdetail",
    component: () => import(/* webpackChunkName: "about" */ "../views/grzx/xmzx/detail/detail.vue"),
  },
  // 我的需求详情
  {
    path: "/grzx/wdzqdetail",
    name: "wdzqdetail",
    component: () => import(/* webpackChunkName: "about" */ "../views/grzx/wdxq/detail/detail.vue"),
  },
  // 导航单页面详情
  {
    path: "/navigationDetail",
    name: "navigationDetail",
    component: () => import(/* webpackChunkName: "about" */ "../views/home/children/navigationDetail.vue"),
  },
  {
    path: "/grzx/wdzqexaimdetail",
    name: "wdzqdetail",
    component: () => import(/* webpackChunkName: "about" */ "../views/grzx/wdxq/detail/exaimDetail.vue"),
  },
  // 项目中心执行中
  {
    path: "/grzx/projectimplement",
    name: "projectimplement",
    component: () => import(/* webpackChunkName: "about" */ "../views/grzx/xmzx/detail/implement.vue"),
  },
  // 项目中心我是需求者详情
  {
    path: "/grzx/demangdetail",
    name: "demangdetail",
    component: () => import(/* webpackChunkName: "about" */ "../views/grzx/xmzx/demand/detail/detail.vue"),
  },
  // 查看推荐人才
  {
    path: "/personnel",
    name: "personnel",
    component: () => import(/* webpackChunkName: "about" */ "../views/xiaoxi/partake/personnel/personnel.vue"),
  },
  // 聊天室
  {
    path: "/chartroom",
    name: "chartroom",
    component: () => import(/* webpackChunkName: "about" */ "../views/xiaoxi/chart/chartroom/chartroom.vue"),
  },
  // 我的案例审核详情
  {
    path: "/wdal/examinedetail",
    name: "wdalexaminedetail",
    component: () => import(/* webpackChunkName: "about" */ "../views/grzx/wdal/detail/examinedetail.vue"),
  },
  // 我的案例详情
  {
    path: "/wdal/Detail",
    name: "wdalDetail",
    component: () => import(/* webpackChunkName: "about" */ "../views/grzx/wdal/detail/detail.vue"),
  },
  // 我的文章详情
  {
    path: "/wdwz/articledetail",
    name: "wdalDetail",
    component: () => import(/* webpackChunkName: "about" */ "../views/grzx/wdwz/detail/articleDetail.vue"),
  },
  // 个人中心
  {
    path: "/grzx",
    name: "grzx",
    redirect: "grzx/gzdt",
    component: () => import(/* webpackChunkName: "about" */ "../views/grzx/index.vue"),
    // 我的案例
    children: [
      {
        path: "wdal",
        name: "WDAL",
        component: () => import(/* webpackChunkName: "about" */ "../views/grzx/wdal/index.vue"),
      },
      // 关注动态
      {
        path: "gzdt",
        name: "gzdt",
        component: () => import(/* webpackChunkName: "about" */ "../views/grzx/gzdt/index.vue"),
      },
      // 机构创作者
      {
        path: "jgcz",
        name: "jgcz",
        component: () => import(/* webpackChunkName: "about" */ "../views/grzx/jgcz/index.vue"),
      },
      // 我的需求
      {
        path: "wdxq",
        name: "wdxq",
        component: () => import(/* webpackChunkName: "about" */ "../views/grzx/wdxq/index.vue"),
      },
      // 我的文章
      {
        path: "wdwz",
        name: "wdwz",
        component: () => import(/* webpackChunkName: "about" */ "../views/grzx/wdwz/index.vue"),
      },
      // 我的收藏
      {
        path: "wdsc",
        name: "wdsc",
        component: () => import(/* webpackChunkName: "about" */ "../views/grzx/wdsc/index.vue"),
      },
      // 好友圈
      {
        path: "hyq",
        name: "hyq",
        component: () => import(/* webpackChunkName: "about" */ "../views/grzx/hyq/index.vue"),
      },
      // 项目中心
      {
        path: "xmzx",
        name: "xmzx",
        component: () => import(/* webpackChunkName: "about" */ "../views/grzx/xmzx/index.vue"),
      },
      // 案例订单
      {
        path: "aldd",
        name: "aldd",
        component: () => import(/* webpackChunkName: "about" */ "../views/grzx/aldd/index.vue"),
      },
      // 设置
      {
        path: "sz",
        name: "sz",
        component: () => import(/* webpackChunkName: "about" */ "../views/grzx/sz/index"),
      },
    ],
  },
  // 消息
  {
    path: "/message",
    name: "Message",
    redirect: "/message/comment",
    component: () => import(/* webpackChunkName: "about" */ "../views/xiaoxi/index.vue"),
    children: [
      // 评论
      {
        path: "comment",
        name: "Comment",
        component: () => import(/* webpackChunkName: "about" */ "../views/xiaoxi/comment/comment.vue"),
      },
      // 新增关注
      {
        path: "follow",
        name: "follow",
        component: () => import(/* webpackChunkName: "about" */ "../views/xiaoxi/follow/follow"),
      },
      // 私信
      {
        path: "chart",
        name: "chart",
        component: () => import(/* webpackChunkName: "about" */ "../views/xiaoxi/chart/chart.vue"),
      },
      // 参与需求
      {
        path: "partake",
        name: "partake",
        component: () => import(/* webpackChunkName: "about" */ "../views/xiaoxi/partake/partake.vue"),
      },
      // 项目通知
      {
        path: "notify",
        name: "notify",
        component: () => import(/* webpackChunkName: "about" */ "../views/xiaoxi/notify/notify.vue"),
      },
      // 其他通知
      {
        path: "other",
        name: "other",
        component: () => import(/* webpackChunkName: "about" */ "../views/xiaoxi/other/other.vue"),
      },
    ],
  },
  
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  let path = []
  let check = path.some((i) => {
    return i === to.path
  })
  if (!check) {
    let sTop = document.documentElement.scrollTop || document.body.scrollTop
    if (sTop > 0) {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }
  }
  next()
})

export default router
