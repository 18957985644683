<!--
* @description header组件
* @fileName index.vue
* @author liulian
* @date 2022/06/07 16:41:11
-->
<template>
  <div class="header-con" :class="[shadow ? 'shadow' : '', isFixed ? 'isfiexed' : '']">
    <div class="con flex">
      <div class="con-left">
        <img class="cursor-pointer" src="@/assets/images/header/header-logo@2x.png" alt="" @click="logoHandle" />
      </div>
      <div class="con-center flex-row-around">
        <div class="search_bar" v-if="searchBar">
          <template v-if="search">
            <img class="cursor-pointer" src="@/assets/images/header/close@2x.png" @click="closeSearch" />
            <el-input v-model="searchInput" placeholder="搜索" @keyup.enter.native="searchHandle"></el-input>
            <img class="search_icon cursor-pointer" src="@/assets/images/header/search@2x.png" @click="searchHandle" />
          </template>
        </div>
        <template v-else>
          <template v-for="(item, index) in menus">
            <span v-if="item.show" class="menu_span font-size-18 cursor-pointer" :class="$route.path === item.path ? 'is-active' : ''" @click="clickMenu(item, index)">
              <template v-if="item.key === 'cwyxs'">
                <template v-if="$store.state.userInfo && $store.state.userInfo.marketer_role === 0">
                  <!-- 判断是否是机构 -->
                  <template v-if="$store.state.userInfo && $store.state.userInfo.is_official_admin === 1"> 成为信用认证机构 </template>
                  <template v-else>
                    {{ item.name }}
                  </template>
                </template>
              </template>
              <template v-else>
                {{ item.name }}
              </template>
            </span>
          </template>
        </template>
      </div>
      <div class="con-right" ref="rightProp">
        <img v-if="searchIcon" class="search_icon cursor-pointer" src="@/assets/images/header/search@2x.png" @click="showSearch" />
        <template v-if="isLogin">
          <el-badge is-dot :hidden="!isCheck">
            <img class="icon cursor-pointer" src="@/assets/images/header/Frame@2x.png" alt="" @click="msgDialog" />
          </el-badge>
          <el-badge is-dot :hidden="true">
            <img v-if="userInfo && userInfo.avatar" class="avatar-img" :src="$store.state.userInfo.avatar" alt="" @click="onprofile" />
            <img v-else class="avatar-img" src="@/assets/images/header/avatar.png" alt="" @click="onprofile" />
          </el-badge>
        </template>
        <!-- 么有登录 -->
        <template v-else>
          <div class="right_box">
            <span class="font-color-blue font-size-16 font-weight-500 cursor-pointer" @click="goLoginHandle">登录</span>
            <span class="liness"></span>
            <span class="font-color-blue font-size-16 font-weight-500 cursor-pointer" @click="registerHandle">注册</span>
          </div>
        </template>
        <div class="props flex-column" v-if="isLogin && propDailog">
          <div class="top text-align-center">
            <span class="title font-size-14 font-weight-500">{{ ($store.state.userInfo && $store.state.userInfo.username) || "用户名" }}</span>
            <div class="top_bottom flex-row-center">
              <span class="red font-color-red font-size-12 text-align-center" v-if="$store.state.userInfo && $store.state.userInfo.level_context"><img src="@/assets/images/home/V@2x.png" alt="" />{{ $store.state.userInfo.level_context }}</span>
              <span class="green font-color-green font-size-12 text-align-center" v-if="$store.state.userInfo && $store.state.userInfo.marketer_role_context">{{ $store.state.userInfo.marketer_role_context }}</span>
            </div>
          </div>
          <div class="center font-size-14 font-weight-500 flex-column-center">
            <span class="cursor-pointer" @click="goProfile">个人中心</span>
            <span class="cursor-pointer" @click="goProject">项目中心</span>
          </div>
          <div class="bottom">
            <span class="font-size-14 font-weight-500 cursor-pointer" @click="outLogin">退出登录</span>
          </div>
        </div>

        <!-- 点击消息icon -->
        <div class="flex-column props1" v-if="showMsgDialog">
          <div class="center font-size-14 font-weight-500">
            <div class="cursor-pointer props1_item" v-for="item in sidelist" :key="item.id" @click="onMessage(item)">
              <el-badge is-dot :hidden="item.value === 0">{{ item.name }}</el-badge>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { countMessageApi } from "@/api/api.js"
export default {
  name: "index",
  components: {},
  props: {
    isFixed: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        // 判断是否需要加阴影
        if (val.path === "/home" || val.path === "/login" || val.path === "/forgotPassword" || val.path === "/register") {
          this.shadow = false
          this.routePath = val.path
        } else {
          this.shadow = true
        }
        if (val.path === "/gd") {
          this.searchBar = true
          this.search = true
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      sidelist: [
        {
          id: 1,
          name: "评论/回复",
          path: "/message/comment",
          value: 0,
          key: "comment",
        },
        {
          id: 2,
          name: "新增关注",
          path: "/message/follow",
          value: 0,
          key: "follow",
        },
        {
          id: 3,
          name: "私信",
          path: "/message/chart",
          value: 0,
          key: "sixin",
        },
        {
          id: 4,
          name: "需求通知",
          path: "/message/partake",
          value: 0,
          key: "demand",
        },
        {
          id: 5,
          name: "项目通知",
          path: "/message/notify",
          value: 0,
          key: "project",
        },
        {
          id: 6,
          name: "其他通知",
          path: "/message/other",
          value: 0,
          key: "other",
        },
      ],
      menus: [
        {
          name: "找social爆款案例",
          path: "/zbkal",
          isActive: false,
          show: true,
        },
        {
          name: "找优秀社媒顾问",
          path: "/zyxyxs",
          isActive: false,
          show: true,
        },
        {
          name: "需求大厅",
          path: "/xqdt",
          isActive: false,
          show: true,
        },
        {
          name: "案例编辑器",
          path: "/albjq",
          isActive: false,
          show: !(this.$store.state.userInfo && this.$store.state.userInfo.official_type === 2),
        },
        {
          name: "成为社媒顾问",
          key: "cwyxs",
          path: "/cwyxs",
          isActive: false,
          show: !(this.$store.state.userInfo && this.$store.state.userInfo.official_type === 2),
        },
      ],
      shadow: true, // 是否显示阴影效果
      routePath: "", // 当前路由
      propDailog: false, // 是否展开菜单弹框
      showMsgDialog: false, // 是否展开菜单弹框
      isLogin: this.$store.state.token ? true : false, // 是否登录,
      userInfo: this.$store.state.userInfo,
      searchBar: false, // 判断当前页面是否显示头部搜索
      searchInput: "", // 输入框绑定的值
      search: false,
      searchIcon: false, // 搜索icon
      isCheck: false, // 是否有未读的消息
    }
  },
  computed: {},
  created() {
    // 从首页传过来的参数
    if (this.$store.state.headerInput) {
      this.searchInput = this.$store.state.headerInput
    }
    // 是否已登录才请求数据
    if (this.isLogin) {
      this.getList()
    }
  },
  mounted() {
    this.checkClick()
  },
  methods: {
    // 获取私信会话列表
    getMessage() {
      var that_ = this
      let promise = this.$tim.getConversationList()
      promise
        .then(function (imResponse) {
          imResponse.data.conversationList.map((e) => {
            if (e.unreadCount > 0) {
              console.log(e)
              that_.sidelist[2].value = 1
            }
          })
        })
        .catch(function (imError) {
          console.warn("getConversationList error:", imError) // 获取会话列表失败的相关信息
        })
    },
    async getList() {
      let data = await this.getMessageData()
      let keys = Object.keys(data)
      keys.map((i) => {
        if (data[i] !== 0) {
          this.isCheck = true
        }
      })
    },
    getMessageData() {
      return new Promise((resolve, rejects) => {
        countMessageApi().then((res) => {
          if (res.code === 10000) {
            let obj = {
              comment: res.data.comment,
              demand: res.data.demand || res.data.demand_refuse || res.data.demand_pass,
              follow: res.data.follow,
              project: res.data.project,
              reply: res.data.reply,
              other: res.data.register || res.data.demand_refuse ||res.data.registerPass,
            }
            this.sidelist.map((item) => {
              if (item.key === "comment") {
                item.value = +res.data.comment + +res.data.reply
              }
              if (item.key === "follow") {
                item.value = +res.data.follow
              }
              if (item.key === "demand") {
                item.value = +res.data.demand || +res.data.demand_refuse ||  +res.data.demand_pass
              }
              if (item.key === "project") {
                item.value = +res.data.project
              }
              if (item.key === "other") {
                item.value =+res.data.register || +res.data.demand_refuse ||  +res.data.registerPass
              }
            })
            resolve(obj)
          }
        })
      })
    },
    /*
     * @Description: 搜索事件
     * @MethodAuthor:  liulian
     * @Date: 2022-07-11 16:09:59
     * @param: ''
     */
    searchHandle() {
      this.$store.dispatch("setSearchKey", this.searchInput)
    },
    /*
     * @Description: 打开搜索框
     * @MethodAuthor:  liulian
     * @Date: 2022-06-30 16:51:39
     * @param: ''
     */
    showSearch() {
      this.searchBar = true
      this.search = true
      this.searchIcon = false
    },
    /*
     * @Description: 隐藏搜索框
     * @MethodAuthor:  liulian
     * @Date: 2022-06-30 16:50:53
     * @param: ''
     */
    closeSearch() {
      this.searchBar = false
      this.search = false
      this.searchIcon = true
      this.searchInput = ""
      this.$store.dispatch("setSearchKey", this.searchInput)
    },
    /*
     * @Description:
     * @MethodAuthor:  liulian
     * @Date: 2022-06-30 16:51:07
     * @param: ''
     */
    checkClick() {
      document.addEventListener("click", (e) => {
        if (this.$refs.rightProp) {
          let isSelf = this.$refs.rightProp.contains(e.target)
          if (!isSelf) {
            this.propDailog = false // 是否展开菜单弹框
            this.showMsgDialog = false // 是否展开菜单弹框
          }
        }
      })
    },
    goLoginHandle() {
      this.$router.push("/login")
    },
    registerHandle() {
      this.$router.push("/register")
    },
    msgDialog() {
      this.propDailog = false
      this.showMsgDialog = !this.showMsgDialog
      this.getMessage()
    },
    /*
     * @Description: 跳转个人中心页面
     * @MethodAuthor:  liulian
     * @Date: 2022-06-10 16:24:30
     * @param: ''
     */
    goProfile() {
      this.propDailog = false
      this.$router.push({ path: "/grzx" })
    },
    outLogin() {
      this.$store.commit("REMOVE_INFO")
      this.propDailog = false
      this.isLogin = this.$store.state.token ? true : false
      let promise = this.$tim.logout()
      promise
        .then(function (imResponse) {
          console.log(imResponse.data) // 登出成功
        })
        .catch(function (imError) {
          console.warn("logout error:", imError)
        })

      this.$router.push("/login")
    },
    onprofile() {
      this.propDailog = !this.propDailog
      this.showMsgDialog = false
    },
    // 项目中心
    goProject() {
      this.$router.push("/grzx/xmzx")
    },
    // 消息

    onMessage(e) {
      this.$router.push(e.path)
    },
    logoHandle() {
      this.propDailog = false
      if (this.$route.path === "/home") {
        return
      }
      this.$router.push({ path: "/home" })
    },
    clickMenu(item, index) {
      this.propDailog = false
      if (!item.isActive) {
        if (item.path === "/albjq") {
          // 判断当前用户是否是营销师，非营销师不能进入案例编辑器
          if (this.$store.state.userInfo && this.$store.state.userInfo.marketer_role === 0) {
            if (this.$store.state.userInfo.is_official_admin === 1) {
              this.$message.info("认证成为Keyy信用合作机构后使用案例编辑器")
            } else {
              this.$message.info("您现在还不是社媒顾问，可前去认证成为社媒顾问后使用案例编辑器！")
            }
          } else {
            item.path && this.$router.push(item.path)
            this.menus.map((it, i) => {
              this.$set(it, "isActive", index === i)
            })
          }
        } else {
          item.path && this.$router.push(item.path)
          this.menus.map((it, i) => {
            this.$set(it, "isActive", index === i)
          })
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.shadow {
  box-shadow: 0px 2px 6px 1px rgba(20, 20, 43, 0.06);
}

.header-con {
  width: 100%;
  background: rgba(255, 255, 255, 0.01);
  opacity: 1;
  display: flex;
  margin: auto;

  .con {
    height: 6.625rem;
    width: 100%;
    margin-left: 2.3125rem;
    margin-right: 6.25rem;
    display: flex;
    justify-content: space-between;
  }

  .con-left {
    text-align: left;
    margin-left: 3.9375rem;

    // flex: 1.6;
    img {
      width: 8.875rem;
    }
  }

  .con-center {
    // flex: 1;
    // margin-left: 23.0625rem;
    // margin-left: 20rem;
    // margin-right: 25.4375rem;
    width: 700px;

    span {
      font-weight: 500;
      color: #111029;
      white-space: nowrap;
      height: 3.625rem;
      padding-left: 1rem;
      padding-right: 1rem;
      line-height: 3.625rem;
      border-radius: 3.25rem;
    }

    .search_bar {
      display: flex;
      align-items: center;
      position: relative;

      img {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
      }

      .search_icon {
        width: 1.4769rem;
        height: 1.4769rem;
        position: absolute;
        right: 0rem;
      }

      ::v-deep.el-input__inner {
        width: 40.625rem;
        height: 3rem;
        background: #f7f7fc;
        border-radius: 2.75rem;
        opacity: 1;
      }
    }

    span:hover {
      color: #3a71ff;
    }

    .is-active {
      background-color: var(--global-color);
      color: #fff;
      height: 3.625rem;
      border-radius: 3.25rem;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      font-weight: 400;
    }

    .is-active:hover {
      background-color: #3a71ff;
      color: #fff;
    }
  }

  .con-right {
    position: relative;
    display: flex;
    align-items: center;

    ::v-deep .el-badge__content.is-fixed.is-dot {
      top: 0.35rem;
      right: 10px;
    }

    .right_box {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: center;
    }

    .search_icon {
      width: 1.4769rem;
      height: 1.4769rem;
      margin-right: 2rem;
    }

    .liness {
      height: 0.8rem;
      width: 0px;
      border-right: 0.125rem solid #3a71ff;
      display: flex;
      display: block;
      margin-left: 0.625rem;
      margin-right: 0.625rem;
    }

    img {
      width: 2.25rem;
      height: 2.25rem;
    }

    .avatar-img {
      margin-left: 2rem;
      cursor: pointer;
      border-radius: 100%;
    }

    .props {
      width: 10.75rem;
      height: 13.5625rem;
      z-index: 9999;
      background-color: #fff;
      background-color: pink;
      position: absolute;
      right: 0rem;
      top: 4.5rem;
      background: #ffffff;
      border-radius: 0px 0px 0.625rem 0.625rem;
      opacity: 1;
      border: 1px solid #eff0f6;

      .top {
        margin-top: 1rem;

        .title {
          color: #111029;
        }

        .top_bottom {
          width: 8.75rem;
          margin: auto;

          img {
            width: 0.4963rem;
            height: 0.5313rem;
          }

          margin-top: 0.5rem;

          .red {
            min-width: 2.625rem;
            height: 1.0625rem;
            background: #ffeff0;
            border-radius: 1.5625rem;
            opacity: 1;
            margin-right: 0.375rem;
          }

          .green {
            min-width: 2.625rem;
            height: 1.0625rem;
            background: #def2e6;
            border-radius: 1.5625rem;
            opacity: 1;
            padding-left: 0.125rem;
            padding-right: 0.125rem;
          }
        }
      }

      .center {
        width: 8.75rem;
        margin: auto;
        margin-top: 1rem;
        border-top: 0.0625rem solid #eff0f6;
        border-bottom: 0.0625rem solid #eff0f6;
        height: 5.5rem;
        align-items: flex-start;

        span {
          height: 1.25rem;
          color: #2f2c42;
        }

        span:nth-child(1) {
          margin-bottom: 1rem;
        }
      }

      .bottom {
        flex: auto;
        display: flex;
        align-items: center;
        width: 8.75rem;
        margin: auto;

        span {
          color: #2f2c42;
        }
      }
    }

    .props1 {
      z-index: 9999;
      position: absolute;
      top: 4.5rem;
      opacity: 1;
      right: 5rem;
      width: 10.75rem;
      // height: 12.25rem;
      background: #ffffff;
      border-radius: 0rem 0rem 0.625rem 0.625rem;
      opacity: 1;
      border: 0.0625rem solid #eff0f6;

      .center {
        width: 8.75rem;
        margin: 0.625rem auto;

        div {
          text-align: left;
          margin-bottom: 10px;
          color: #2f2c42;
        }
      }

      .props1_item {
        ::v-deep .el-badge__content.is-fixed.is-dot {
          right: 2px;
          top: 5px;
        }

        ::v-deep .header-con .con-right .props1 .center div[data-v-613e5ae0] {
          margin-bottom: 0rem;
        }
      }
    }
  }
}
.isfiexed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
}
</style>
