import axios from "axios"
import { Message, MessageBox } from "element-ui"
import router from "../router/index"
import store from "../store/index"

const baseConfig = {
  baseURL: "",
  timeout: 30000,
  transformRequest: [
    function (data) {
      data = JSON.stringify(data)
      return data
    },
  ],
  //   在传给then之前修改响应数据
  transformResponse: [
    function (data) {
      if (typeof data === "string" && data.startsWith("{")) {
        data = JSON.parse(data)
      }
      return data
    },
  ],
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
}

const httpService = axios.create(baseConfig)
const http = axios.create(baseConfig)

// 请求合并
let reqNum = 0 // 请求的次数
let showMag = false // 特殊状态处理
function startLoading() {
  if (reqNum === 0) {
  }
  reqNum++ // 请求次数加一
}

// 延迟300ms再调用closeLoading方法，合并300s内的请求
function endLoading() {
  setTimeout(closeLoading, 300)
}

// 关闭加载
function closeLoading() {
  if (reqNum <= 0) {
    return
  }
  if (reqNum === 0) {
  }
  reqNum-- // 请求次数减一
  if(reqNum === 0 && showMag) {
    MessageBox.alert("登录过期，是否重新登录！", {
      confirmButtonText: "重新登录",
      type: "warning",
    })
      .then(() => {
        store.commit("REMOVE_INFO")
        if (window.location.pathname !== "/login") {
          router.push("/login")
        }
        MessageBox.close()
      })
      .catch(() => {
        MessageBox.close()
      })
  }
}

// 请求拦截函数
const requestIterceptor = (config) => {
  const token = localStorage.getItem("token")
  //   config.headers["Authorization"] = token
  if (token) {
    // config.headers["Authorization"] = "Bearer " + token;
    config.headers["Authorization"] = token
  }
  // loading.open() //打开加载窗口
  startLoading()
  return config
}

// 请求拦截器
http.interceptors.request.use(requestIterceptor, (error) => {
  return Promise.resject(error)
})
httpService.interceptors.request.use(requestIterceptor, (error) => {
  return Promise.reject(error)
})

const errorHandle = (status, message) => {
  if (status) {
    switch (status) {
      case 10008:
        MessageBox.alert("该账号已被禁用，请联系平台管理员", {
          type: "warning",
        })
          .then(() => {
            store.commit("REMOVE_INFO")
            router.push("/login")
            MessageBox.close()
          })
          .catch(() => {
            MessageBox.close()
          })
        break
      case 10009:
        // MessageBox.alert("登录过期，是否重新登录！", {
        //   confirmButtonText: "重新登录",
        //   type: "warning",
        // })
        //   .then(() => {
        //     store.commit("REMOVE_INFO")
        //     if (window.location.pathname !== "/login") {
        //       router.push("/login")
        //     }
        //     MessageBox.close()
        //   })
        //   .catch(() => {
        //     MessageBox.close()
        //   })
        break
      case 404:
        Message({
          message: message,
          type: "error",
        })
        break
      case 20001:
        Message({
          message: message,
          type: "error",
        })
        break
      default:
        Message({
          message: message,
          type: "error",
        })
    }
  }
}

// 响应拦截器
httpService.interceptors.response.use((response) => {
  showMag = false
  const data = response.data
  // if(response.data.code==10009){
  //     router.push('/login')
  // }
  if (response.status === 200) {
    endLoading()
    if (response.data.code !== 10000) {
      endLoading()
      errorHandle(data.code, data.message)
    }
    // 判断token是否有效
    if (response.data.code === 1016002 || response.data.code === 10009) {
    showMag = true
      // MessageBox.alert("登录过期，是否重新登录！", {
      //   confirmButtonText: "重新登录",
      //   type: "warning",
      // })
      //   .then(() => {
      //     store.commit("REMOVE_INFO")
      //     if (window.location.pathname !== "/login") {
      //       router.push("/login")
      //     }
      //     MessageBox.close()
      //   })
      //   .catch(() => {
      //     MessageBox.close()
      //   })
    }

    // loading.close() //关闭加载窗口
    return Promise.resolve(data)
  } else {
    errorHandle(data.code, data.message)
    endLoading()
    return Promise.reject(data.message)
  }
})

http.interceptors.response.use((response) => {
  return Promise.resolve(response)
})

export default httpService
export { http }
