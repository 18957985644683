const util = {
    // 字符串截取
    substr: function (str, num = 4, string = "...") {
      if (str) {
        let result = str.length > num ? `${str.slice(0, num)}${string ? string : ""}` : str
        return result
      }
    },
  
    // 判断是否是数组类型
    isArray :function(something) {
      return Object.prototype.toString.call(something) === '[object Array]'
    },
    // 单位转换
    formatNumber:function(num) {
      num = Number(num);
      if (num == 0) {
        return num + '';
      } else
      if (!(num < 1) && num < 10000) {
        return num + '';
      } else {
        return (num / 10000).toFixed(2) + 'W';
      }
    }
  } 
  
  module.exports = {
    substr: util.substr,
    isArray:util.isArray,
    formatNumber:util.formatNumber
  }
  