<template>
  <div id="app">
    <div v-if="showLoding">
      <myHeader :key="key" :isFixed="isFiex()"></myHeader>
      <div>
        <router-view></router-view>
      </div>
      <myFooter v-if="isShowFooter()" :key="`key-${key}`"></myFooter>
    </div>
    <div v-show="!showLoding">
      <div class="loadAnimation">
        <img id="img1" :src="imgUrl" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { getUig } from "@/api/api"
import myHeader from "@/components/header/index"
import myFooter from "@/components/footer/index"
import settingsVue from "./views/grzx/sz/basicsettings/settings.vue"
import { type } from "os"
export default {
  components: {
    myHeader,
    myFooter,
  },
  data() {
    return {
      key: "",
      showLoding: false,
      imgUrl: require("@/assets/images/loadingImg.gif"),
    }
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        let el = document.getElementById("office-iframe")
        el && (el.style.display = "none") // 关闭编辑器
        this.key = new Date().getTime()
      },
      // 深度观察监听
      immediate: true,
    },
  },
  created() {
    this.init()
  },
  mounted() {
    if (localStorage.getItem("token")) {
      this.getuisg()
    }
  },

  beforeCreate() {
    // this.checkPlatform()
    let os = (function () {
      let ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        isChrome = /(?:Chrome|CriOS)/.test(ua),
        isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isPc = !isPhone && !isAndroid && !isSymbian
      let obj = {
        isTablet: isTablet,
        isPhone: isPhone,
        isAndroid: isAndroid,
        isPc: isPc,
      }
      return obj
    })()
    if (os.isPc) {
    } else {
      this.$store.commit("REMOVE_INFO")
      let url = `${this.$url}#`
      let path = ""
      // 爆款详情
      if (location.pathname === "/zbkalDetail") {
        path = `pages/bkal/children/detail/detail${location.search}`
        // location.replace(`${url}`)
      }
      // 需求详情
      if (location.pathname === "/xqdtDetail") {
        path = `pages/xqdt/children/detail/detail${location.search}`
        // location.replace(`${url}`)
      }
      // 文章
      if (location.pathname === "/wdwz/articledetail") {
        path = `pages/my/article/detail${location.search}`
        // location.replace(`${url}`)
      }
      // 文章
      if (location.pathname === "/wdwz/articledetail") {
        path = `${url}/pages/my/article/detail${location.search}`
      }
      // 项目需求方详情
      if (location.pathname === "/grzx/demangdetail") {
        path = `${url}/pages/my/projectcenter/detail/needdetail${location.search}`
      }
      // 项目创建人
      if (location.pathname === "/grzx/projectdetail") {
        path = `${url}/pages/my/projectcenter/detail/detail${location.search}`
      }
      location.replace(`${url}/${path}`)
    }
  },
  methods: {
    init() {
      this.imgUrl = `${require("@/assets/images/loadingImg.gif")}?${new Date().getTime()}`
      // setTimeout(() => {
      //   this.showLoding = true
      // }, 3000)

      setTimeout(() => {
        this.showLoding = true
      }, 2000)
    },
    getuisg() {
      getUig().then((res) => {
        if (res.code == 10000) {
          let promise = this.$tim.login({ userID: res.data.user_id.toString(), userSig: res.data.user_sig })
          promise
            .then(function (imResponse) {
              if (imResponse.data.repeatLogin === true) {
                // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
              }
            })
            .catch(function (imError) {})
        }
      })
    },
    /*
     * @Description: 是否显示底部
     * @MethodAuthor:  liulian
     * @Date: 2022-06-09 14:14:39
     * @param: ''
     */
    isShowFooter() {
      let pathArr = ["/home", "/zbkalDetail", "/xqdtDetail", "/grzx/wdzqdetail", "/grzx/wdzqexaimdetail", "/wdal/Detail", "/wdwz/articledetail"]
      const check = pathArr.some((i) => {
        if (i === this.$route.path) {
          return true
        }
      })
      return check
    },

    /*
     * @Description: 是否固定头部
     * @MethodAuthor:  liulian
     * @Date: 2022-08-19 17:47:44
     * @param: ''
     */
    isFiex() {
      let arrPath = ["/albjq"]
      const check = arrPath.some((i) => {
        if (i === this.$route.path) {
          return true
        }
      })
      return check
    },
  },
}
</script>
<style lang="scss">
body {
  padding: 0;
  margin: 0;
}

.loadAnimation {
  width: 100vw;
  height: 100vh;
  background-color: black;
}

.loadAnimation {
  top: 50%;
  left: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  z-index: 100;

  img {
    height: 340px;
    top: 40%;
    left: 40%;
    position: absolute;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    z-index: 100;
  }
}

#app {
  font-family: PingFang SC-Regular, PingFang SC;
  --global-color: #111029;
  --global-width: 71%;
  height: 100vh;
}
</style>
